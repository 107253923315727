$background: #1f2730;
$background-light: #39434d;
$accent: #000;
$font-color: rgba(255, 255, 255, 0.65);

body {
  color: $font-color!important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6, li {
  color: $font-color !important;
}

p {
  font-size: 19px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: rgb(162, 175, 194);
}

a:hover {
  color: rgb(202, 220, 247);
}

.bg-dark {
  background: $background !important;
}

.bg-light {
  background: $background-light !important;
  /* background: #2f3a47 !important; */
}

.big-num {
  font-size: 8rem;
  margin: 0;
  margin-top: -2.5rem;
  padding: 0;
  vertical-align: top;
  line-height: 1;
}

.blurdots {
  filter: blur(calc(var(--blur) * 0.25));
}

.btn-main {
  height: 3rem;
  width: 20rem;
  font-size: 1.1rem;
  display: grid;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
}

.btn-main:hover {
  text-shadow: 0 0 0.7rem white;
}

.btn-contact {
  height: 3rem;
  width: 24rem;
  font-size: 1.3rem;
  display: grid;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  text-transform: none !important;
}

.btn-dark {
  background-color: $accent !important;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: inset 1px 1px 5px 0px rgb(0,0,0,0.4);
  border: none;
  transition: linear 0.2s;
  border-radius: 0.75rem;
  color: white;
}

.btn-light {
  background-color: $background;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: inset 1px 1px 5px 0px rgb(0,0,0,0.4);
  border: none;
  transition: linear 0.2s;
  border-radius: 0.75rem;
  color: white;
}

.btn-light:hover {
  background-color: $background !important;
  color: #fff;
}

.btn-outline-secondary {
  text-decoration: none;
  color: rgba(255, 255, 255, .55);
  border-color: rgba(255, 255, 255, .55);
}

.btn-outline-secondary:hover {
  background-color: rgba(255, 255, 255, 0.75);
  color: #212529;
}

.btn-primary {
  background-color: #000;
  border-color: #000;

  &:hover {
    background-color: #95A8B1 !important;
    border-color: #95A8B1 !important;
  }
}

.button-link {
  padding-left: 0;
  padding-right: 0;
}

.card {
  background-color: transparent;
}

.card-body {
  padding: 1.2rem;
}

.counter-span {
  display: inline-block;
  width: 100px;
  text-align: right;
}

.drop-shadow {
  box-shadow: 2px 2px 10px rgba(0,0,0,0.4);
  transition: linear 0.2s;
}

.drop-shadow:hover {
  box-shadow: 2px 2px 20px rgba(0,0,0,0.4);
}

.elastic-scroll {
  transition: transform 0.2s ease-out;
}

.glow {
  text-shadow: 0 0 0.1rem white;
  animation: 4s glow ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 0.1rem white;
  }
  to {
    text-shadow: 0 0 0.8rem white;
  }
}

.gradient {
  background: linear-gradient(155deg, #574851 0%, #95A8B1 100%);
}

.hero {
  box-shadow: 5px 5px 25px rgba(0,0,0,0.5);
  background: $accent !important;  
  border-radius: 1rem;
}

.icon-align {
  vertical-align: middle;
}

.form-control {
  background-color: #39434d;
  border-color: #39434d;
  color: #95A8B1 !important;
}

.input-group .form-control {

  &::placeholder {
    color: #95A8B1 !important;
    opacity: 1; // Ensure the color is fully opaque
  }
}

.form-floating > label {
  color: #95A8B1 !important;
}

.item-label {
  letter-spacing: 0.1rem;
  font-weight: 600;
}

.link {
  color: rgba(255, 255, 255, .95);
  border: none !important;
  border-bottom: 1px solid #e6e9ed2a !important;
  background: none !important;
}
 
.link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.loading {
  animation: loadingAnimation 1s infinite;
}

@keyframes loadingAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.mockup.tilt {
  transform-style: preserve-3d;
  transform: perspective(1000px) rotate3d(0, 1, 0, -30deg)  rotateX(20deg);
}

.mockup-label {
  color: #fff;
  z-index: 999;
  font-size: 1rem;
}

.navbar-toggler {
  border: none !important;
}

.profile-pic {
  max-width: 200px;
}

.rgb {
  position: relative;
  width: 300px;
  height: 2px;
  background: #fff;
  -webkite-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.rgb::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, #fb0094, #0000ff,
  #00ff00, #ffff00, #fb0094, #0000ff, #00ff00, #ffff00, #fb0094);
  animation: rgb 20s linear infinite;
  background-size: 500%;
}

.rgb::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, #fb0094, #0000ff,
  #00ff00, #ffff00, #fb0094, #0000ff, #00ff00, #ffff00, #fb0094);
  animation: rgb 20s linear infinite;
  background-size: 500%;
  filter: blur(20px);
}

.rgb-btn {
  position: relative;
  -webkite-box-reflect: below 1px linear-gradient(transparent, #0005);
}

.rgb-btn {
  background: linear-gradient(#000, #000) padding-box,
  linear-gradient(90deg, #fb0094, #0000ff,
  #00ff00, #ffff00, #fb0094, #0000ff, #00ff00, #ffff00, #fb0094) border-box;
  animation: rgb 20s linear infinite;
}

.rgb-btn::after {
  content: '';
  position: absolute;
  inset: 15px;
  background:linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #fb0094, #0000ff,
  #00ff00, #ffff00, #fb0094, #0000ff, #00ff00, #ffff00, #fb0094) border-box;
  animation: rgb 20s linear infinite;
  border: 6px solid transparent;
  background-size: 500%;
  filter: blur(10px);
}

.rgb-inner {
  box-shadow: inset 0px 33px 25px 0 #fb0094, 
  inset 0 66px 15px 0px #0000ff,
  inset 0 99px 5px 0px #00ff00;
  animation: rgb-inner 20s linear infinite;
}

@keyframes rgb-inner
{
  to {
    --bg-angle: 360deg;
  }
}

@keyframes rgb
{
  0%
  {
    background-position: 0 0;
  }
  0%
  {
    background-position: 200% 0;
  }
}

.section-label {
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.slashes {
  display: block;
  padding-top: 0.5rem;
  text-decoration: none;
  color: rgba(255,255,255,.55);
}

.smooth {
  transition: 0.3s ease-out;
}

.subscribe {
  font-size: 1rem;;
}

.text-fade {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  display: table;
  width: fit-content;
}

.text-gradient {
  background: linear-gradient(97.13deg, rgba(255, 255, 255, 1.0) 0%, rgba(255, 255, 255, 0.5) 100%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  display: table;
  width: fit-content;
}

.text-gradient-long {
  background: linear-gradient(160deg, rgba(255, 255, 255, 1.0) 0%, rgba(255, 255, 255, 0.5) 55%) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  display: table;
  width: fit-content;
}

.text-highlight {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  padding: 2rem;
  border-radius: 1em;
  background-color: $accent;
}

.text-main {
  letter-spacing: 0.02rem;
}

.text-muted {
  color: rgba(255, 255, 255, 0.2) !important;
  font-size: 1rem;
}


@media (max-width: 991px) {
  /* MOBILE RULES */
  .big-num {
    margin-top: -2rem !important;
  }

  .btn-main {
    height: 3rem;
    width: 100%;
  }

  .counter-span {
    width: 90px !important;
  }

  .hero {
    padding: 1rem !important;
    padding-top: 1.3rem !important;
    margin-bottom: 1rem;
  }

  .mobile-mt {
    margin-top: 0.5rem;;
  }

  .mobile-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar-expand-lg {
    display: none;
  }

  .profile-pic {
    display: block;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .section {
    padding: 0 0 1rem 0;
  }

  .section-label {
    margin-top: 1rem;
    font-size: 1.3rem;
  }

  .text-gradient-break {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
  }

  .text-highlight {
    padding: 1.2rem;
  }
}

@media (min-width: 992px) {
  /* DESKTOP RULES */
  .btn-main {
    height: 3rem;
    width: 20rem;
  }
  .hero {
    padding: 3rem !important;
    margin-bottom: 3rem;
  }

  #navburger {
    display: none;
  }

  .new-line-mobile {
    display: none;
  }

  .slashes {
    padding: 0.5rem;
  }

  .profile-pic {
    float: right !important;
  }

  .project:not(:first-of-type) {
    margin-top: 3rem;
  }

  .section {
    padding: 0 3rem 3rem;
  }

  .section-label {
    font-size: 2rem;
  }

  .text-gradient-break {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.57) !important;
  }
}

.navbar-brand, .nav-link {
  color: rgba(255,255,255,.55) !important;
}

.navbar-brand.active, .nav-link.active {
  color: rgba(255, 255, 255, 1) !important;
}

.navbar-brand:hover, .nav-link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

.nav-link, .nav-link:hover, .link, .link:hover, .navbar-brand, .navbar-brand:hover {
  transition: color 100ms linear;
}

.img-fit { float: left; width: 100%; height: 200px; object-fit: cover; }




/*
.link:after {
  content: " »";
}

.link {
  padding: 0 4px;
  border-radius: 4px;
  text-decoration: none;
  color: rgba(255, 255, 255, .95);
  transition: background-color 100ms linear;
}

.link:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.10);
}
*/
 
